import {Serializer} from '@matchsource/api-utils';
import {DocumentDetails} from '@matchsource/api-generated/documents';
import {DocumentModel} from '@matchsource/models/documents';

export const documentsSerializer: Serializer<DocumentModel, DocumentDetails> = {
  fromDTO(input: DocumentDetails): DocumentModel {
    return {
      id: input.id,
      name: input.title,
      isNew: false,
    };
  },
};
